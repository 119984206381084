@import '../../../../../../../styles/_variables.scss';

.company-list-item {
  border-radius: 5px;
  padding: 16px;

  display: grid;

  grid-template-areas:
    'title id'
    'desc desc';

  grid-template-columns: 1fr auto;
  gap: 16px;

  background-color: $dark-bg-3;

  cursor: pointer;

  border: 1px solid transparent;

  &:hover {
    border-color: $primary-color-1;

    .company-list-item__title {
      color: $primary-color-1;
    }
  }
}

.company-list-item--overdue {
  background-color: #4f181c;
}

.company-list-item__title {
  grid-area: title;

  font-size: 18px;
  color: $light-text-color-1;

  display: flex;
  align-items: center;
}

.company-list-item__id {
  grid-area: id;

  font-size: 12px;
  color: $light-text-color-4;

  display: flex;
  align-items: center;
}

.company-list-item__description {
  grid-area: desc;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.company-list-item__description-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.company-list-item__description-prop {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 12px;
}

.company-list-item__description-prop-name {
  color: $light-text-color-2;
  font-weight: 500;
  width: 100px;
}

.company-list-item__description-prop-value {
  color: $light-text-color-3;
}

.company-list-item__description-message {
  font-size: 10px;
  color: $light-text-color-3;
}
