@import '../../../../../styles/_variables.scss';

.main-layout-header {
  display: grid;
  grid-template-areas: 'main devider nav additional';
  grid-template-columns: auto auto 1fr auto;

  background-color: $dark-bg-2;

  padding: 0 30px;
}

.main-layout-header__main-zone {
  grid-area: main;
}

.main-layout-header__devider-zone {
  grid-area: devider;
  display: flex;
  align-items: center;
}

.main-layout-header__devider {
  margin: 0 16px;
  width: 1px;
  height: 30px;
  background-color: $dark-bg-5;
}

.main-layout-header__nav-zone {
  grid-area: nav;
}

.main-layout-header__additional-zone {
  grid-area: additional;
}
