@import '../../../../../../styles/_variables.scss';

.header-additional {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 16px;
}

.header-additional__devider {
  height: 32px;
  width: 2px;
  background-color: $dark-bg-5;
}
