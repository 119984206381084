.add-tp-form {
  display: flex;
  gap: 8px;
}

.add-tp-form__select {
  flex: 1;
}

.add-tp-form__submit {
}
