.register-step-one {
  height: 60px;
  padding: 20px;
  &__password {
    height: 60px;
    padding: 20px;
  }
  &__phone {
    input {
      height: 60px;
      padding: 20px;
    }
  }
}
