@import '../../../../styles/_variables.scss';

.exec-result-prop {
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.exec-result-prop__label {
}

.exec-result-prop__value {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $text-color;
  gap: 4px;
}
