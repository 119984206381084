@import '../../../../styles/_variables.scss';

.edit-pd-collection-in-cad-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.edit-pd-collection-in-cad-modal__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-pd-collection-in-cad-modal__disabled-row {
  background-color: $light-bg-1;
}
