.exec-in-process-container {
  display: grid;
  grid-template-areas:
    'queue exec'
    'history history';
  gap: 16px;
  grid-template-columns: 1fr auto;
}

.exec-in-process-container__queue {
  grid-area: queue;
}

.exec-in-process-container__exec {
  grid-area: exec;
}

.exec-in-process-container__history {
  grid-area: history;
}
