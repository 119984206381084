.management-statistic__button {
  font-size: 24px;
  color: rgba(24, 144, 255, 1);

  font-weight: 600;
  &.active {
    color: black;

    font-size: 24px;
    font-weight: 600;
  }
}
