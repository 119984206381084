.field-filling-wizard-modal {
}
.field-filling-wizard-modal__step-content {
  padding: 24px 16px 16px;
}

.field-filling-wizard-modal__selected-items-addition {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.field-filling-wizard-modal__autocompleteAll-cbx {
  margin-left: auto;
}

.field-filling-wizard-modal__transfer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field-filling-wizard-modal__field-list {
  display: flex;
  flex-direction: column;
}

.field-filling-wizard-modal__field-list-item {
  display: grid;
  grid-template-areas:
    'number fieldName .'
    'number input clear';
  grid-template-columns: 40px 1fr 150px;
  gap: 16px;

  padding-top: 16px;
  border-bottom: 1px solid #e2e2e2;
}

.field-filling-wizard-modal__field-list-item-number {
  grid-area: number;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.field-filling-wizard-modal__field-list-item-field-name {
  grid-area: fieldName;
}
.field-filling-wizard-modal__field-list-item-field-name-prefix {
  color: #b2b2b2;
  margin-right: 10px;
}

.field-filling-wizard-modal__field-list-item-input {
  grid-area: input;
}

.field-filling-wizard-modal__field-list-item-clear {
  grid-area: clear;
  display: flex;
  align-items: center;
}

.field-filling-wizard-modal__final-form-warning {
  margin-top: 16px;
}

.field-filling-wizard-modal__final-form-warning-alert {
  text-transform: uppercase;
  color: red;
}
