.edit-file-collection-base {
}

.edit-file-collection-base__table {
  margin-bottom: 16px;
}

.edit-file-collection-base__new-file {
  margin-bottom: 16px;
}

.edit-file-collection-base__upload {
}
