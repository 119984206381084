@import '../../../../styles/_variables.scss';

.login-form {
  &__email {
    height: 60px;
    padding: 20px;
  }
  &__password {
    height: 60px;
    padding: 20px;
  }
}

.login-form__buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.login-form__forgot-password {
  align-self: center;
  color: $light-text-color-4;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    color: $light-text-color-3;
  }
}
