.pd-record-popover {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
}

.pd-record-popover__desc-item {
  display: flex;
  align-items: baseline;
}

.pd-record-popover__prop-name {
  margin-right: 8px;
  font-weight: 500;
}

.pd-record-popover__prop-value {
}
