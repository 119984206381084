.guest-layout-footer-link {
  color: #707070;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-size: 12px;

  &:hover {
    color: #c4c4c4;
  }
}
