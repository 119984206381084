.boundary-info__layout {
    padding: 10px;
}

.boundary-cadaster {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &__layout {
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border: 1px solid #E6E6E6;
        position: relative;
    }
    &__title {
        position: absolute;
        top: -13px;
        left: 30px;
        color: #1890FF;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translate(-50%, -50%);
            width: 65px;
            height: 20px;
            background: #fff;
            z-index: 1;
        }
        & p {
            position: relative;
            z-index: 2;
            margin: 0;
        }
        & span {
            z-index: 2;
            margin-right: 5px;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 23px;
                height: 23px;
                border-radius: 50%;
                border: 1px solid #1890FF;
            }
        }
    }
}
