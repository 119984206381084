@import '../../../../../../styles/_variables.scss';

.header-nav-menu {
  background-color: transparent;

  border-bottom-color: transparent;
  border-bottom-width: 0px;

  line-height: 70px;

  .ant-menu-item,
  .ant-menu-submenu {
    &::after {
      left: 0;
      right: 0;
      bottom: 1px;
    }
    &:hover::after {
      border-bottom: 4px solid $primary-color-1 !important;
    }

    &:hover {
      .ant-menu-title-content,
      .-title-content {
        color: $light-text-color-1;
      }
    }

    .ant-menu-title-content,
    .-title-content {
      color: $light-text-color-3;
    }
  }
}
