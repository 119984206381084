@import '../../styles/_variables.scss';

.contracts {
  &__title {
    font-size: 18px;
    margin-bottom: 15px;
    color: $light-text-color-3;
  }

  &__favorite {
    margin-bottom: 30px;
  }
}
