.main-table-page-table__wrapper {
  padding: 16px 32px;
}

.main-table-page-table__loader-block {
  padding: 20px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-table-page-table__exclude {
  color: rgb(189, 136, 57);
}
