.forward-btn__content {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.forward-btn__forward-icon {
  font-size: 12px;
}
