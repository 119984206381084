@import '../../../../styles/_variables.scss';

.history-table {
  padding: 8px;
  background: $light-bg-1;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

.history-table__header {
  font-weight: 500;
}
