.loading-layout {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-layout__logo {
  width: 20%;
  margin-bottom: 18px;

  animation-duration: 3s;
  animation-name: breathe;
  animation-iteration-count: infinite;
}

@keyframes breathe {
  from {
    opacity: 30%;
  }

  50% {
    opacity: 70%;
  }

  to {
    opacity: 30%;
  }
}

.loading-layout__logo-img {
  object-fit: contain;
}

.loading-layout__hint {
  position: relative;
}

.loading-layout__hint-spinner {
  position: absolute;
  left: calc(100% + 16px);
  bottom: 0;

  font-size: 20px;
}

.loading-layout__hint-text {
  color: #666666;
}
