.main-layout {
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-areas:
    'header'
    'content'
    'footer'
    'app_state';
}

.main-layout--single-page {
  max-height: 100vh;

  .main-layout__content {
    overflow-y: hidden;
  }
}

.main-layout__header {
  grid-area: header;
}

.main-layout__content {
  grid-area: content;
  max-width: 100vw;
}

.main-layout__footer {
  grid-area: footer;
}

.main-layout__app-state {
  grid-area: app_state;
}
