.management-data {
  &__wrapper {
    margin: 15px 30px 25px 30px;
    background-color: rgba(255, 255, 255, 1);
    padding: 20px;
  }
  &__main-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__requisites {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-address__row {
      height: 30px;
      padding-bottom: 8px;
    }
  }
}

.subtitle2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  color: rgba(183, 183, 183, 1);
  margin-top: 40px;
}
