@import '../../styles/_variables.scss';

.info-popup {
  border-radius: 100%;
  background-color: $primary-color-1;
  border: 1px solid $border-color;
  font-size: 11px;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background-color: $primary-color-2;
  }
}
