@import '../../styles/_variables.scss';

.file-card {
  border: 1px solid $light-bg-2;
  padding: 8px;

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  grid-template-rows: auto auto;
  grid-gap: 8px;

  grid-template-areas:
    'title name name delete'
    'size size uploadOn delete';
}

.file-card--with-no-title {
  grid-template-areas:
    'name name name delete'
    'size size uploadOn delete';

  & .file-card__name {
    text-align: left;
  }
}

.file-card--with-no-title .file-card__name {
  text-align: left;
}

.file-card__title {
  grid-area: title;
  font-weight: 700;
}

.file-card__name {
  text-align: right;
  grid-area: name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-card__size {
  grid-area: size;
}

.file-card__upload-on {
  grid-area: uploadOn;
  color: $light-text-color-4;
}

.file-card__delete {
  grid-area: delete;
  display: flex;
  align-items: center;
}
