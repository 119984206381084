@import '../../../../../../../styles/_variables.scss';

.header__container {
  height: 100%;
  display: flex;
  align-items: center;
}

.header__avatar__container {
  height: 100%;
  display: flex;
  align-items: center;
}
.header__avatar {
  cursor: pointer;

  &_welcome {
    padding: 10px 15px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &Text {
      font-size: 12px;
      color: $light-text-color-3;
    }

    &Name {
      font-size: 16px;
    }

    &Role {
      font-size: 12px;
      font-style: italic;
    }
  }

  &_dropdown {
    width: 200px;
    padding: 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border: 1px solid #e2e2e2;
    z-index: 10;

    .ant-dropdown-menu {
      box-shadow: none;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      font-size: 16px;

      &:last-child {
        color: red;
      }

      &:hover {
        background: $light-bg-2;

        a {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
}

.ant-dropdown-menu-item > a.header__avatar_logout {
  color: $red-color;
}
