@import '../../../styles/_variables.scss';

.statistic-bar {
  padding: 5px 30px;
  // background: #3d4b58;
  background-color: $dark-aqua-1;
  display: flex;
  gap: 16px;
  align-items: center;
}

.statistic-bar__btn {
  color: white;
  background: $dark-aqua-2;
  border: 1px solid #313e45;

  &:hover,
  &:active {
    // background: #abaeb7 !important ;
    background-color: $light-text-color-3;
    color: $dark-text-color-3;
    border: 1px solid #313e45 !important;
  }

  &:focus {
    background: #6f7177 !important ;
    color: #373737;
    border: 2px solid #1890ff !important;
  }

  &--notify {
    background: #1890ff;
  }
}

.statistic-bar__right {
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-self: stretch;
  align-items: center;
}

.statistic-bar__prop-list {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.statistic-bar__prop {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.statistic-bar__prop--all-objects {
  display: flex;
  flex-direction: row;
  gap: 8px;

  .statistic-bar__prop-name {
    width: min-content;
  }

  .statistic-bar__prop-value {
    align-items: end;
  }

  .statistic-bar__prop-value-count {
    font-size: 24px;
  }
}

.statistic-bar__prop-name {
  font-weight: 200;
  color: #e5e5e5;
  font-size: 12px;
  line-height: 100%;
}

.statistic-bar__prop-value {
  display: flex;
  gap: 8px;
  align-items: center;
}

.statistic-bar__prop-value-count {
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  color: white;
}

.statistic-bar__prop-value-percent {
  font-size: 12px;
  line-height: 100%;
  color: #95a9bc;
}

.statistic-bar__prop-value-comment {
  font-size: 10px;
  line-height: 100%;
  color: #8c9eaf;
}

.statistic-bar_detail-btn {
  height: 100%;
  min-width: 50px;
}

.statistic-bar__notify-btn-container {
  height: 100%;
  display: flex;
  align-items: center;
}
