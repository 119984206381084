.server-config-table {
  &__excluded-row {
    td {
      background-color: rgb(228, 227, 227);
    }

    .data-cell{
      pointer-events: none;
    }
  }
  &__remarks-row {
    td {
      background-color: #ffbbbb;
    }
  }
  &__done-row {
    td {
      background-color: #d2fcd4;
    }
  }

  &__default-row {
    .with-data{
      background-color: #d2fcd4;
    }

    .soglasNo-filled{
      background-color: #ffbbbb;
    }
  }

  
}

.server-config-table__address-cell {
  .editable-base__input-container {
    min-width: 200px;
  }
}
