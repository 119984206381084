.coords-import-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.coords-import-modal__text-area {
  width: 100%;
}
.coords-import-modal__error-message {
  color: red;
}
.coords-import-modal__table {
}
