@import './styleVariables';

.coords-manager {
  max-height: 100%;
  padding: 8px;

  display: grid;
  grid-template-areas: 'canvas info';
  grid-template-columns: auto 1fr;

  &__canvas {
    grid-area: canvas;
  }
  &__info {
    grid-area: info;
  }
}
