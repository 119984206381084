.boundary-header__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F6FBFF;
    padding: 30px 35px;
}

.boundary-header__col {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    & h2 {
        margin: 0;
    }
}

.boundary-header__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

