@import '../../../../../../styles/_variables.scss';

.tp-item {
  padding: 8px;
  border: 2px solid $border-color;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background-color: $light-bg-1;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.tp-item--selected {
  border-color: $primary-color-1;
}

.tp-item__title {
  flex: 1;
  cursor: pointer;

  &:hover {
    color: $text-color;
  }
}
