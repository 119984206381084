@import '../../../../../styles/_variables.scss';

.contract {
  display: block;
  padding: 30px;
  color: #111;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border: 1px solid #e2e2e2;
  transition: all 0.3s ease;
  cursor: pointer;

  &__title {
    font-size: 24px;
    line-height: 1.5;
    font-weight: 500;
  }

  &__info {
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $light-text-color-3;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &_name {
        margin-right: 16px;
        white-space: nowrap;
      }

      &_value {
        white-space: nowrap;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__favorite {
    &_star {
      svg {
        width: 24px;
        height: 24px;
      }

      &-outline {
        color: #cdcdcd;
        transition: all 0.3s ease;

        &:hover {
          color: $yellow-color;
        }
      }

      &-filled {
        color: $yellow-color;
      }
    }
  }

  &:hover {
    color: $primary-color-1;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transform: translateY(-5px);
  }
}
