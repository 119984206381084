.management-header {
  padding: 15px 30px;

  &__subtitle {
    font-size: 9px;
    font-weight: 500;
    &-text {
      font-weight: 300;
    }
  }

  &__title {
    padding: 10px 0 15px 0;
    &-name {
      font-size: 32px;
      font-weight: 500;
    }
    &-price {
      font-size: 26px;
      font-weight: 400;
    }

    &-link {
      font-size: 10px;
      font-weight: 400;
      text-decoration: underline;
      color: rgba(24, 144, 255, 1);
      :hover {
        cursor: pointer;
      }
    }
    &-paid {
      font-size: 10px;
      font-weight: 400;
      color: rgba(112, 112, 112, 1);
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      & > button {
        height: 22px;
        border-radius: 3px;
        border: 0.5px solid rgba(24, 144, 255, 1);
        font-size: 10px;
      }

      &-generate {
        margin-right: 10px;
        display: flex;
      }
      &-add {
        height: 22px;
        border-radius: 3px;
        border: 0.5px solid rgba(24, 144, 255, 1);
      }
    }
  }
}
