@import '../../../../../../../styles/_variables.scss';

.polyline-point-item {
  padding: 4px;
  display: flex;
  border-radius: 4px;
  border: 2px solid transparent;
  justify-content: space-between;
  gap: 8px;

  &:hover {
    background-color: $light-bg-1;
  }

  cursor: pointer;
}

.polyline-point-item--selected {
  cursor: default;

  border: 2px solid $primary-color-1;

  .polyline-point-item__actions {
    visibility: visible;
    opacity: 1;
  }

  .polyline-point-item__add-button {
    display: block;
  }
}

.polyline-point-item__cords-container {
  display: flex;
  gap: 16px;
  align-items: center;
  flex: 1;
}

.polyline-point-item__actions {
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s;
}

.polyline-point-item__cord {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
}

.polyline-point-item__cord-name {
  color: $light-text-color-3;
  width: 20px;
}
.polyline-point-item__cord-value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.polyline-point-item__add-button {
  display: none;
  height: 18px;
  line-height: 1;
  padding: 0;
}
