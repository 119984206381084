.guest-layout {
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-template-areas: 'bg panel';
  min-height: 100vh;

  & .guest-layout--no-scroll {
    max-height: 100vh;
  }
}

.guest-layout__image-container {
  grid-area: bg;
  overflow: hidden;
}

.guest-layout__bg-container {
  grid-area: bg;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guest-layout__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left center;
}

.guest-layout__panel {
  max-height: 100%;
  min-height: 100%;

  grid-area: panel;
  padding: 16px 32px;
  background-color: #111111;

  display: grid;
  gap: 16px;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    'header'
    'content';
}

.guest-layout__header {
  grid-area: header;
}

.guest-layout__content {
  grid-area: content;
  overflow: hidden;
}
