.coords-table {
  width: 100%;
}

.coords-table__input {
  width: 100%;
}

.coords-table__td {
  padding: 5px 10px;
}

.coords-table__error-td {
  color: #ff4d4f;
  padding: 5px 10px;
}
