.xml-cell {
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.xml-cell__actions {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 8px;
}

.xml-cell__document {
  display: flex;
  align-items: center;
  gap: 6px;
}
.xml-cell__document-name {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.xml-cell__document-size {
  white-space: nowrap;
}
.xml-cell__document-delete-button {
}
.xml-cell__document-delete-button:hover {
  color: red;
  transition: 0.5s;
}
