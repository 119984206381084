@import './../../../../styles/_variables.scss';

.filter-type-select {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 8px;
}

.filter-type-select__items-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.filter-type-select__selected-item-label {
  flex: 1;
}
