.cad-quarter-input {
  display: flex;
  gap: 18px;
  align-items: center;
}

.cad-quarter-input__input {
  flex: 1;
}

.cad-quarter-input__quarter {
  color: #707070;
}
