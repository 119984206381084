.cad-object-num-selector {
}

.cad-object-num-selector__selected-items-addition {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.cad-object-num-selector__autocompleteAll-cbx {
  margin-left: auto;
}

.cad-object-num-selector__selected-items {
}
