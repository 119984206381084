@import '../../styles/_variables.scss';

.button-with-menu {
  border-radius: 3px;
  border: 0.5px solid $primary-color-1;
  transition: 0.5s;

  .ant-btn {
    font-size: 10px;
    border-radius: 0;
  }

  &:hover {
    background-color: $primary-color-1;
  }
}

.button-with-menu__overlay {
  border-radius: 3px;
  border: 0.5px solid $border-color;
  background-color: white;

  .ant-dropdown-menu {
    padding: 0;

    .ant-dropdown-menu-item {
      font-size: 12px;
      padding: 5px 10px;
      line-height: 1.5715;
      transition: 0.5s;

      &:not(:last-child) {
        border-bottom: 0.5px solid $border-color;
      }
    }
  }
}
