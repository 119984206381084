.management-employee__role-label-text {
  margin: 0 10px 0 0;
}

.management-employee__info-wrapper {
  padding: 6px 9px;
  background-color: #e5e5e5;
}

.management-employee__info-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;

  color: #000000;
}

.management-employee__info-subtitle {
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 8px;

  color: #000000;
}
