.create-pd-form {
  display: grid;
  grid-template-columns: 2fr minmax(400px, 1fr);
  grid-template-areas: 'form file';
  grid-gap: 16px;
}

.create-pd-form__title {
  grid-area: title;
}

.create-pd-form__form {
  grid-area: form;
}

.create-pd-form__file-zone {
  grid-area: file;
  padding-top: 30px;

  display: flex;
  flex-direction: column;
}

.create-pd-form__file {
  margin-bottom: 16px;
}

.create-pd-form__upload {
  flex-grow: 1;
}
