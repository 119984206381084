.management-wrapper {
  background-color: rgba(246, 251, 255, 1);
  height: 100%;
}

.save-button {
  margin: 9px 0 9px 33px;
}

.custom-management__footer {
  padding: 15px 0;
  font-size: 12px;
  color: #bdbdbd;
}

.management-item__wrapper {
  margin: 15px 30px;
}

.paper {
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
}
