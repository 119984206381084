@import '../../../../../../../styles/_variables.scss';

.header-logo-title {
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Raleway', sans-serif;

  &--without-text {
    .header-logo-title__logo-text {
      display: none;
    }
  }
}

.header-logo-title__logo-icon {
  color: $primary-color-1;
  font-size: 30px;
}

.header-logo-title__logo-text {
  line-height: 1;
  font-size: 24px;
  font-weight: 600;
  font-size: 24px;
  color: $light-text-color-1;
}

.header-logo-title__logo-text-beta {
  position: relative;
  top: -14px;
  font-weight: 300;
  color: $light-text-color-3;
  font-size: 10px;
}
