@import '../../../../assets/globals.scss';

.companies-page-header__title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  color: $light-text-color-1;
}

.companies-page-header__description {
  font-size: 12px;
  color: $light-text-color-2;
}
