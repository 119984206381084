.permission-settings-input {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.permission-settings-input__info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.permission-settings-input__info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.permission-settings-input__info-item-title {
  font-weight: 500;
  font-size: 16px;
}
