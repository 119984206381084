@import '../../styles/_variables.scss';

.cad-object-coord-page {
  display: flex;
  flex-direction: column;
}

.cad-object-coord-page__back_to-contract {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cad-object-coord-page__header {
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.cad-object-coord-page__title {
  font-weight: 500;
  font-size: 16px;
}

.cad-object-coord-page__have-changes {
  font-size: 10px;
  color: $light-text-color-1;
}

.cad-object-coord-page__content {
  flex: 1;
}
