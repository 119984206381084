.external-link-ceil {
  min-height: 30px;

  &--ellipsis {
    a {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
