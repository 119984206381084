@import '../../../../../styles/_variables.scss';

.polyline-list {
  padding: 16px;
  border: 1px solid $border-color;
  border-radius: 4px;
}

.polyline-list__header {
  font-weight: 500;
  margin-bottom: 16px;
}

.polyline-list__items-container {
  border-radius: 4px;
  background-color: $light-bg-1;
}

.polyline-list__polyline-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.polyline-list__polyline-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.polyline-list__add-polyline-zone {
  margin-top: 16px;
  display: flex;
  gap: 8px;
}

.polyline-list__add-polyline {
  flex: 1;
}
