.filter-dropdown-actions{
    display: flex;
    align-items: center;
    gap: 8px;
}

.filter-dropdown-actions_set-filter-btn{
    flex: 1;
}

.filter-dropdown-actions_reset-filter{
    flex: 1;
}