@import '../../styles/_variables.scss';

.popover-container {
  background: $light-bg-0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: 1px solid $border-color;
  padding: 16px;

  &.popover-container--dark {
    background: $dark-bg-1;
    border: 1px solid black;
  }
}
