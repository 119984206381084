.notify-overlay{
    background-color: #3D4B58;
    width: 400px;
    padding: 16px;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateY(-10px);
}

.notify-overlay__no-one-notify{
    color: rgb(224, 224, 224)
}

.notify-overlay__header{
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    color: white;
}

.notify-overlay__action-list{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.notify-overlay__action-item{
    padding: 8px;
    background-color: #505E6B;
    border-radius: 4px;

    display: grid;
    grid-template-columns: 70px 1fr auto;
    grid-template-areas: 
    'header header launch'
    'count showLink launch';
    gap: 4px;
}


.notify-overlay__action-item-header{
    grid-area: header;
    color: rgb(255, 255, 255);
}

.notify-overlay__action-item-launch{
    grid-area: launch;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notify-overlay__action-item-count{
    grid-area: count;
    color: #81E7BC;
    font-size: 18px;
}

.notify-overlay__action-item-show-link{
    grid-area: showLink;
    display: flex;
    align-items: center;
}