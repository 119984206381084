@import '../../styles/_variables.scss';

.file-card-couple {
  border: 1px solid $border-color;
  padding: 8px;

  display: grid;
  grid-template-areas:
    'title title .'
    'first second delete';

  grid-template-columns: 1fr 1fr auto;
  gap: 8px;

  &__title {
    grid-area: title;
    font-weight: 700;
  }

  &__first {
    grid-area: first;
  }

  &__second {
    grid-area: second;
  }

  &__delete {
    grid-area: delete;
    height: 100%;
  }
}

.file-card-couple-item {
  padding: 8px;
  display: grid;
  grid-template-areas:
    'name name'
    'size uploadAt';
  grid-template-columns: 1fr auto;
  gap: 8px;
  border: 1px dashed silver;

  &__name {
    grid-area: name;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__size {
    grid-area: size;
    color: $light-text-color-4;
  }

  &__uploadAt {
    grid-area: uploadAt;
    color: $light-text-color-4;
  }
}
