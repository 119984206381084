@import '../../styles/_variables.scss';

$dark-bg: #3d4b58;

.dashboard-button {
  display: flex;
  align-items: center;
  //padding: 5px 10px;
  background-color: transparent;
  border-radius: 3px;
  border: 0.5px solid $primary-color-1;
  transition: 0.5s;
}
.dashboard-button:hover {
  background-color: $primary-color-1;
}

.dashboard-button:hover .dashboard-button__icon {
  color: white;
}
.dashboard-button:hover .dashboard-button__text {
  color: white;
}

.dashboard-button .ant-btn-loading-icon {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: $primary-color-1;
}
.dashboard-button__icon {
  display: flex;
  align-items: center;
  color: $primary-color-1;
  margin-right: 6px;
  font-size: 12px;
}

.dashboard-button__text {
  display: flex;
  align-items: center;
  font-size: 10px;

  line-height: 12px;
}
