@import '../styleVariables';

.cords-manager-canvas {
  background-color: #f6f6f6;
  border: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.cords-manager-canvas__area {
  background-color: white;
}

.cords-manager-canvas__actions-panel {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  display: flex;
  border: 1px solid $border-color;
  background-color: rgba(255, 255, 255, 0.247);
  border-radius: 8px;
  gap: 8px;
}
