.guest-layout-header {
  display: flex;
  align-items: center;
}

.guest-layout-header__logo-img {
  width: 30px;
  height: 30px;
}

.guest-layout-header__logo-text {
  color: white;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  line-height: 1;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
}

.guest-layout-header__logo-beta {
  display: block;
  position: relative;
  font-size: 10px;
  font-weight: 300;
  top: 2px;
  left: 5px;
  color: #bdbdbd;
}
