@import '../styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

html,
body {
  font-family: 'Rubik', sans-serif;
}

.wrap {
  padding: 40px 30px;
}

// 2. Basic elements
.page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    &_left {
      display: flex;
      align-items: baseline;
    }

    &_title {
      font-size: 45px;
      line-height: 45px;
      font-weight: 600;
      margin-right: 30px;
    }

    &_subtitle {
      font-size: 30px;
      font-weight: 400;
      color: $light-text-color-4;
    }
  }
}

// 3. Ant overwrites
.ant-message {
  top: 70px;
}

.y-scroll {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: gray;
    width: 16px;

    &:hover {
      background-color: rgb(198, 198, 198);
    }
  }
}
