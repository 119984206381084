@import '../../../../../../styles/_variables.scss';

.register-in-progress-step {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'title'
    'content'
    'hint';
  gap: 16px;
}

.register-in-progress-step__title {
  grid-area: title;
  font-size: 18px;
  color: white;
}

.register-in-progress-step__content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.register-in-progress-step__content-loader {
  margin-bottom: 32px;
}
.register-in-progress-step__content-title {
  font-size: 12px;
  color: white;
  margin-bottom: 16px;
}
.register-in-progress-step__content-description {
  font-size: 10px;
  color: $light-text-color-2;
}

.register-in-progress-step__hint {
  grid-area: hint;
}
