@import '../../../../styles/_variables.scss';

.exec-item-view {
  display: flex;
  border: $border-color 1px solid;
  color: rgba(0, 0, 0, 0.85);
  background-color: $light-bg-0;
}

.exec-item-view__body {
  padding: 0 8px;
}

.exec-item-view__time {
  border-left: $border-color 1px solid;
  padding: 0 8px;
}
