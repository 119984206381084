$primary-color-1: #1890ff;
$primary-color-2: #51a8fa;

$red-color: #d32f2f;
$yellow-color: #e0e013;
$bg-color: #212121;
$text-color: rgba(0, 0, 0, 0.8);

$dark-text-color-1: rgb(20, 20, 20);
$dark-text-color-2: rgb(40, 40, 40);
$dark-text-color-3: rgb(90, 90, 90);

$light-text-color-1: white;
$light-text-color-2: rgb(230, 230, 230);
$light-text-color-3: rgb(200, 200, 200);
$light-text-color-4: rgb(150, 150, 150);

$light-bg-0: white;
$light-bg-1: rgb(247, 247, 247);
$light-bg-2: rgb(235, 235, 235);
$light-bg-3: rgb(231, 231, 231);

$dark-bg-1: #0e0e0e;
$dark-bg-2: #242424;
$dark-bg-3: #313131;
$dark-bg-4: #494949;
$dark-bg-5: #626262;
$dark-bg-6: #848484;

$bg-error: rgba(79, 24, 28, 1);

$dark-aqua-1: #35434a;
$dark-aqua-2: #3d4b58;

$border-color: #e7e7e7;
