.management-menu {
  background-color: rgba(230, 243, 254, 1);
  width: 100%;
  padding: 15px 30px;
  &__item {
    font-size: 16px;
    text-decoration: underline;
    font-weight: 400;
    color: rgba(24, 144, 255, 1);
    :hover {
      cursor: pointer;
    }
  }
  &__item.active {
    font-size: 16px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
  }
}
