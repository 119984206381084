.create-cad-object-modal {
}

.create-cad-object-modal__desc {
  margin-bottom: 16px;
}

.create-cad-object-modal__form .ant-input-number {
  width: 100%;
}
