@import '../../../../../../styles/_variables.scss';

.polyline-item {
  padding: 8px;
  border: 1px solid $border-color;
}

.polyline-item--selected {
  background-color: white;

  .polyline-item__header {
    margin-bottom: 8px;
  }

  .polyline-item__points-container {
    max-height: 200px;
    overflow-y: auto;
  }
}

.polyline-item__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.polyline-item__title {
  font-weight: 500;
}

.polyline-item__actions {
  display: flex;
  align-items: center;
}

.polyline-item__points-container {
  flex-direction: column;
  display: flex;
  max-height: 0px;
  transition: 0.2s;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }
}
