.zero-quarter-form-wrapper {
  margin-top: 12px;
}

.zero-quarter-form-row {
  display: flex;
  margin-bottom: 8px;
}

.zero-quarter-input {
  margin-bottom: 0;
}

.zero-quarter-minus-icon {
  color: red;
}
