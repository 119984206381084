@import '../../../../../../../../styles/_variables.scss';

.company-icon-overlay {
  color: $light-text-color-2;
  border-radius: 3px;
  min-width: 400px;
}

.company-overlay {
  min-width: 450px;

  &__button-control {
    height: 40px;
    width: 100%;
  }
  &__button-wrapper {
    margin-top: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    .ant-space-item {
      width: 100%;
    }
  }

  &__button-change-company {
    height: 40px;
    width: 100%;

    color: $red-color;
    :hover {
      color: $red-color;
    }
    ::after {
      color: $red-color;
    }
  }
  &__title {
    &-field {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 11px;
      color: rgba(255, 255, 255, 1);
    }
    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      margin: 5px 0 10px 0;
      color: rgba(255, 255, 255, 1);
    }
  }
  &__subtitle {
    &-field {
      font-size: 12px;
      font-weight: 500;
    }
    &-text {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
