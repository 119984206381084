@import '../../../../../../../styles/_variables.scss';

.widget-container {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 12px;
  border-right: 1px solid $dark-bg-6;
  border-left: 1px solid $dark-bg-6;
  transition: 0.3s;

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $dark-bg-5;
    }
  }
}
