.batch-file-download-result-step {
  display: flex;
  align-items: center;
  gap: 40px;
}

.batch-file-download-result-step__info {
}

.batch-file-download-result-step__main {
  font-weight: 600;
}
.batch-file-download-result-step__desc {
  margin-bottom: 16px;
}
