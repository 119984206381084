.companies-page-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.companies-page-footer__link {
  color: #707070;
  text-decoration: underline;
  font-size: 12px;
  white-space: nowrap;

  &:hover {
    color: #c4c4c4;
  }
}
