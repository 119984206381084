@import '../../styles/_variables.scss';

$border-color: #e7e7e7;
$hover-bg-color: #f5f5f5;
$dark-bg: #3d4b58;

.app-dropdown-button {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 3px;
  border: 0.5px solid $primary-color-1;
  transition: 0.5s;
}
.app-dropdown-button:hover {
  background-color: $primary-color-1;
}

.app-dropdown-button:hover .app-dropdown-button__icon {
  color: white;
}
.app-dropdown-button:hover .app-dropdown-button__text {
  color: white;
}
.app-dropdown-button:hover .app-dropdown-button__down {
  color: white;
}

.app-dropdown-button.app-dropdown-button--as-text {
  border: none !important;
  background: none !important;
  padding: 0 !important;
}

.app-dropdown-button.app-dropdown-button--dark {
  background-color: $dark-bg;
}

.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__icon {
  color: white;
}
.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__text {
  color: white;
}
.app-dropdown-button.app-dropdown-button--dark .app-dropdown-button__down {
  color: white;
}

.app-dropdown-button__icon {
  display: flex;
  align-items: center;
  color: $primary-color-1;
  margin-right: 6px;
}

.app-dropdown-button__text {
  display: flex;
  align-items: center;
  font-size: 10px;

  line-height: 12px;
}

.app-dropdown-button__down {
  display: flex;
  align-items: center;
  font-size: 8px;
  margin-left: 8px;
  color: $primary-color-1;
}

.app-dropdown-button__overlay {
  border-radius: 3px;
  border: 0.5px solid $border-color;
  background-color: white;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.app-dropdown-button__overlay-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 0.5px solid $border-color;
  transition: 0.5s;
  cursor: pointer;
}

.app-dropdown-button__overlay-item:hover {
  background-color: $hover-bg-color;
}

.app-dropdown-button__overlay-item--danger {
  color: #ff4d4f;

  &:hover {
    background-color: #ff4d4f;
    color: white;
  }
}

.app-dropdown-button__overlay-item-text {
  font-size: 12px;
}

.app-dropdown-button__overlay-item-icon {
  margin-right: 12px;
}
