@import '../../styles/_variables.scss';

.app-spinner {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;

  border-radius: 50%;

  border: 5px solid rgba(0, 0, 0, 0.2);

  border-top-color: $primary-color-1;
  border-right-color: $primary-color-1;

  animation: app-spinner-rotating 1.2s linear infinite;
}

@keyframes app-spinner-rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.app-spinner--dark {
  border-left-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.app-spinner--small {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
}

.app-spinner--large {
  min-width: 75px;
  min-height: 75px;
  max-width: 75px;
  max-height: 75px;
}

.app-spinner--gigant {
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
}
