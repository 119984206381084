.section-header {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 10px;

  &__title {
    font-weight: 700;
    font-size: 18px;
    flex: 0;
    white-space: nowrap;
  }

  &__divider {
    flex: 1;
    height: 1px;
    background-color: #e5e5e5;
  }
}
