.select-declarant {
}

.select-declarant__prop-name {
  font-weight: 500;
  color: #707070;
}

.select-declarant__prop-value {
  color: #707070;
}
