@import '../../../../../../styles/_variables.scss';

.header-main-menu {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-main-menu__company-zone {
  height: 100%;
  margin-right: 16px;
}

.header-main-menu__logo-zone {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.header-main-menu__logo-select {
  min-width: 215px;
  border-radius: 5px;
  .ant-select-selector {
    background-color: #3d4b58 !important;
    border: 1px solid #3d4b58 !important;
    color: white;
  }
  .ant-select-arrow {
    color: white;
  }
}

.header-main-menu__additional-zone {
  margin-left: 16px;
}
