.register-step-three {
  &__select {
    height: 60px;
    :first-child {
      height: 60px !important;
      :nth-child(2) {
        padding: 15px 0 0 15px;
      }
    }
    :nth-child(2) {
      height: 60px !important;
      :nth-child(2) {
        padding: 15px 0 0 15px;
      }
    }
  }
  &__email {
    height: 60px;
    padding: 20px;
  }
}
