.load-file-modal {
}

.load-file-modal__desc {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.load-file-modal__desc:not(:last-child) {
  margin-bottom: 16px;
}

.load-file-modal__desc-section {
}

.load-file-modal__upload-list {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.load-file-modal__upload-list-change-on {
  align-self: center;
}

.load-file-modal__accept_changes {
  align-self: flex-end;
  margin-top: 16px;
}
