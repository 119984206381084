$subheader-bg: #f6fbff;

.sub-header {
  background-color: $subheader-bg;
  padding: 8px 30px;
  display: flex;
  gap: 10px;
  overflow-y: auto;

  &__left {
    display: flex;
  }

  &__right {
    margin-left: auto;
    display: flex;
  }
}
