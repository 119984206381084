.main-table-page {
  .main-layout__content {
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    height: 100%;
  }

  &__search-select {
    border-radius: 5px !important;
    min-width: 215px !important;
    margin-left: 20px;
  }

  &__statistic-bar {
    position: sticky;
    bottom: 0;
    width: 100%;
  }
}
