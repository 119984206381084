@import '../../styles/_variables.scss';

.list-with-title {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $light-bg-1;
  border-radius: 4px;
  padding: 8px;
}

.list-with-title__title {
  font-weight: 500;
}

.list-with-title__list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
