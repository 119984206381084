$color: #1890ff;
$dark-bg: #3d4b58;

.app-select-search {
  display: flex;
  align-items: center;
  //padding: 5px 10px;
  background-color: transparent;
  border-radius: 3px;
  border: 0.5px solid $color;
  transition: 0.5s;
  font-size: 10px;
  min-width: 150px;

  & .ant-select-selector {
    border: none !important;
    background: none !important;
  }
}
.app-select-search.app-select-search--dark {
  border: none;
}
.app-select-search.app-select-search--dark {
  background-color: $dark-bg;
  border-color: $dark-bg;
}

.app-select-search.app-select-search--dark .ant-select-arrow {
  color: white;
}

.app-select-search.app-select-search--dark .ant-select-selection-search-input {
  color: white;
}
.app-select-search.app-select-search--dark .ant-select-selection-item {
  color: white;
}

.app-select-search .ant-select-arrow {
  align-self: center;
  font-size: 8px;
  display: flex;
  align-items: center;
}

.app-select-search__icon {
  display: flex;
  align-items: center;
  color: $color;
  margin-right: 6px;
  font-size: 12px;
}

.app-select-search__text {
  display: flex;
  align-items: center;
  font-size: 10px;

  line-height: 12px;
}

.app-select-search__down {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 8px;
  color: $color;
}
