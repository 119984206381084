.boundary__form {
    padding: 15px 30px;
    background-color: #F6FBFF;
    min-height: 100vh;
}

.boundary-cadaster__form-layout {
    padding: 32px;
    background-color: #ffffff;
    border-radius: 5px;
}
