.companies-section {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.companies-section__list-wrapper {
  flex: 1;

  overflow-y: auto;
}
