@import '../../../../../styles/_variables.scss';

.tp-list {
  padding: 16px;
  border: 1px solid $border-color;
  border-radius: 4px;
}

.tp-list__header {
  font-weight: 500;
  margin-bottom: 16px;
}

.tp-list__items-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
