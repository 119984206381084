.dadata-antd-input {
}

.dadata-antd-input__container {
  position: relative;
}

.dadata-antd-input__input {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  width: 100%;

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }

  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important;
    outline: 0;
  }

  &::-webkit-input-placeholder,
  &::placeholder {
    color: #807676;
    opacity: 0.6;
  }
}

.dadata-antd-input__suggestions {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% + 4px);
  z-index: 100;

  padding: 4px 0;
  background-color: white;
  border-radius: 2px;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 0;
}

.dadata-antd-input__suggestion {
  display: block;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
  background-color: transparent;
  border-radius: 0;
  border: none;

  &:hover {
    background-color: #f5f5f5;
  }

  &--current {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #e6f7ff;
  }
}
