.companies-page {
  min-height: 100vh;
  max-height: 100vh;
}

.companies-page__content {
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'companies'
    'footer';

  grid-template-rows: auto 1fr auto;
  gap: 16px;
}

.companies-page__header {
  grid-area: header;
}

.companies-page__companies {
  grid-area: companies;
  overflow: hidden;
}

.companies-page__footer {
  grid-area: footer;
}
