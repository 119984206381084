.contract-statistic-overlay {
  background-color: #3d4b58;
  width: 850px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateY(-10px);
}

.contract-statistic-overlay__no-one-notify {
  color: rgb(224, 224, 224);
}

.contract-statistic-overlay__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.contract-statistic-overlay__title {
  font-size: 16px;

  font-weight: 500;
  color: white;
}

.contract-statistic-overlay__actions {
  display: flex;
  gap: 8px;
}

.contract-statistic-overlay__stat-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.contract-statistic-overlay__stat-item {
  padding: 8px;
  background-color: #505e6b;
  border-radius: 4px;

  display: grid;
  grid-template-columns: 1fr 55px 50px 70px;
  grid-template-areas:
    'header count percent show'
    'comment comment comment comment';
  gap: 0 8px;
  align-items: center;
}

.contract-statistic-overlay__stat-item-header {
  grid-area: header;
  color: #e5e5e5;
  font-size: 12px;
}

.contract-statistic-overlay__stat-item-count {
  grid-area: count;
  color: #81e7bc;
  font-size: 12px;
}

.contract-statistic-overlay__stat-item-percent {
  grid-area: percent;
  color: #95a9bc;
  font-size: 12px;
}

.contract-statistic-overlay__stat-item-comment {
  padding-top: 4px;
  grid-area: comment;
  color: #e7e7e7;
  font-size: 12px;
}

.contract-statistic-overlay__stat-item-show-btn {
  grid-area: show;
  font-size: 12px;
}
