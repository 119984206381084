.batch-file-download-select-file-type-step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.batch-file-download-select-file-type-step__file-type-field {
  margin-bottom: 40px;
}
.batch-file-download-select-file-type-step__file-type-field-select {
  width: 100%;
}

.batch-file-download-select-file-type-step__hint {
  margin: 0 50px;
}
