.management-finance {
  &__wrapper {
    margin: 15px 30px;
  }
  &__left-card {
    &-recommend {
      margin-bottom: 15px;
    }
    &-chip {
      background-color: rgba(208, 235, 255, 1);
      border-radius: 8px;
      padding: 6px 10px;
      color: rgba(112, 112, 112, 1);
      font-size: 20px;
      display: inline-flex;
      &-subtitle {
        margin-left: 5px;
        font-size: 10px;
      }
    }

    &-document-button {
      height: 30px;
      width: 100%;
    }
    &-row-form {
      align-items: center;
    }
    &-balance {
      display: flex;
    }
    &-total {
      color: red;
      font-size: 26px;
      font-weight: 400;
    }
    &-row-flex {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    &-date {
      font-size: 12px;
      font-weight: 400;
    }
    &-item {
      border-radius: 5px;
      background-color: #ffffff;
      padding: 20px;
    }
    &-secondary {
      color: rgba(112, 112, 112, 1);
      font-weight: 300;
      font-size: 12px;
    }
    &-plan {
      background-color: rgba(237, 251, 255, 1);
      border-radius: 10px;
      padding: 10px 15px;
      margin: 15px 0;
    }
    &-field {
      font-weight: 500;
      font-size: 10px;
    }
    &-tariff {
      font-weight: 400;
      font-size: 12px;
    }
    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0em;
      margin-bottom: 20px;
    }
    &-header {
      font-size: 12px;
      font-weight: 400;
    }
    &-data {
      font-weight: 500;
      font-size: 24px;
      display: inline-flex;
      align-items: flex-end;
    }
    &-span {
      margin-left: 4px;
      font-weight: 400;
      font-size: 10px;
      color: rgba(112, 112, 112, 1);
    }
  }
}
