@import '../../../../../styles/_variables.scss';

.app-state-widget {
  background-color: $dark-bg-4;
  height: 25px;
  display: flex;
  padding: 2px 30px;
  justify-content: space-between;
  font-size: 10px;
  color: $light-text-color-3;

  &__left-side {
    display: flex;
  }

  &__right-side {
    display: flex;
  }
}
