@import '../../styles/_variables.scss';

.hint-block {
  border-radius: 2px;
  padding: 16px;
  display: grid;
  grid-template-areas:
    'icon title'
    'desc desc';
  grid-template-columns: 30px 1fr;
  gap: 24px;

  background-color: $light-bg-3;
  &--dark {
    background-color: $dark-bg-3;

    .hint-block__title {
      color: $light-text-color-1;
    }

    .hint-block__description {
      color: $light-text-color-2;
    }
  }
}

.hint-block__icon {
  grid-area: icon;

  svg {
    width: 100%;
    height: 100%;
  }

  &--info {
    color: $primary-color-1;
  }
}

.hint-block__title {
  display: flex;
  align-items: center;
  grid-area: title;
  color: $light-text-color-1;

  font-size: 14px;
  font-weight: 500;
}

.hint-block__description {
  grid-area: desc;

  font-size: 12px;
  font-weight: $light-text-color-2;
}
