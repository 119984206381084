@import '../../../../../../../styles/_variables.scss';

.header-icon-button {
  height: 100%;
  display: flex;
  align-items: center;

  &.header-icon-button--large {
    .header-icon-button__icon {
      font-size: 32px;
    }
  }

  &:hover {
    .header-icon-button__icon {
      color: $light-text-color-3;
    }
  }
}

.header-icon-button__icon {
  font-size: 24px;
  color: $light-text-color-4;
  transition: 0.3s;
}
