.register-page__block-content {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'form'
    'footer';
}

.register-page__form {
  margin-bottom: 24px;
  grid-area: form;
}

.register-page__footer {
  grid-area: footer;
}
