.import-base {
  display: flex;
  flex-direction: column;
}

.import-base__desc {
  padding: 14px;
  border: 0.5px solid #e6e6e6;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'example title'
    'example list';
  gap: 8px;
  column-gap: 26px;
  margin-bottom: 18px;
}

.import-base__desc-title {
  font-size: 14px;
  font-weight: bold;
  grid-area: title;
}
.import-base__desc-example {
  grid-area: example;
}

.import-base__desc-example-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.import-base__list {
  grid-area: list;
  list-style: decimal;
}

.import-base__list-item {
  font-size: 11px;
}
