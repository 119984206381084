.register-finish-step {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.register-finish-step__title {
  font-size: 34px;
  margin: 20px 0 0 0;
  color: white;
}

.register-finish-step__description {
  font-size: 16px;
  color: rgb(206, 206, 206);
}

.register-finish-step__back_to-login {
  font-size: 12px;
  margin: 40px 0 0 0;
  background-color: #1890ff;
  height: 60px;
}
