@import '../../../../../styles/_variables.scss';

$container-size: 30px;

.filter-type-select__option {
  border: 2px solid $light-text-color-3;
  color: $light-text-color-3;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $container-size;
  height: $container-size;
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: -7px;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    transition: 0.3s;
  }

  &--selected {
    border-color: $primary-color-1;
    color: $primary-color-1;
  }

  &--hasFilter {
    &::after {
      background: $primary-color-1;
    }
  }
}
